import { Suspense } from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import { Ring } from './components';
import { urlParams } from './utils';

export const App: React.FC = () => {
  return (
    <Canvas { ...{
      camera: {
        position: [ 0.5, 0, 0 ],
        near: 0.001,
      },
    } }
    >
      <OrbitControls />
      <directionalLight intensity={ 0.3 } position={ [ 1, 1, 1 ] } />
      <directionalLight intensity={ 0.3 } position={ [ -1, 1, 1 ] } />
      <directionalLight intensity={ 0.3 } position={ [ 1, 1, -1 ] } />
      <directionalLight intensity={ 0.3 } position={ [ -1, -1, -1 ] } />
      <Suspense fallback={ null }>
        <Ring { ...{
          shownLeftImage: Boolean( urlParams.leftImage ),
          shownRightImage: Boolean( urlParams.rightImage ),
          shownRuby: Boolean( urlParams.rubyColor ),
          RubyColor: urlParams.rubyColor,
          RightText: urlParams.leftText,
          LeftText: urlParams.rightText,

        } }
        />
      </Suspense>
      <axesHelper />
    </Canvas>
  );
};
