import React, { useRef } from 'react';
import * as THREE from 'three';
import { useGLTF, CurveModifier, CurveModifierRef } from '@react-three/drei';
import { useFrame, useThree } from '@react-three/fiber';
import { Flow } from 'three/examples/jsm/modifiers/CurveModifier';
// eslint-disable-next-line import/extensions
import JSONfont from './helvetiker_regular.json';
// import { Controls, useControl } from 'react-three-gui';

const font = new THREE.FontLoader().parse( JSONfont );


interface IStateProps {
  shownRightImage?: boolean;
  shownLeftImage?: boolean;
  shownRuby?: boolean;
  RubyColor?: string;
  RightText?: string;
  LeftText?: string;
}

const defaultProps = {
  shownRightImage: true,
  shownLeftImage: true,
  shownRuby: true,
  RubyColor: '',
  RightText: '',
  LeftText: '',
};

export const Ring: React.FC<IStateProps> = props => {
  const {
    shownRightImage,
    shownLeftImage,
    shownRuby,
    RubyColor,
    RightText,
    LeftText,
  } = props;
  const group = useRef< any >( null! );
  const ref2 = useRef< any >( null! );
  const textLineRef = useRef< any >( null! );
  const RingGLTF: any = useGLTF( './withLine.glb', true );
  const { scene } = useThree();

  const curveRef = React.useRef<CurveModifierRef>();
  const geomRef = React.useRef<THREE.TextGeometry>( null! );
  const geomRef2 = React.useRef<THREE.TextGeometry>( null! );

  const handlePos = React.useMemo(
    () => [
      { x: 0.08, y: 0.08, z: -0.08 },
      { x: 0.1, y: 0.09, z: 0 },
      { x: 0.08, y: 0.08, z: 0.08 },
    ].map( hand => new THREE.Vector3( ...Object.values( hand ) ) ),
    [],
  );

  const curve = React.useMemo( () => new THREE.CatmullRomCurve3( handlePos, false, 'centripetal' ), [ handlePos ] );

  const line = React.useMemo(
    () => new THREE.LineLoop(
      new THREE.BufferGeometry().setFromPoints( curve.getPoints( 50 ) ),
      new THREE.LineBasicMaterial( { color: 0x00ff00 } ),
    ),
    [ curve ],
  );

  // let a = 0;

  useFrame( () => {
    // if( curveRef.current ) {
    //   curveRef.current?.moveAlongCurve(0.001);
    // }

    // a += 0.001;

    // console.log(a);

    // geomRef.current?.rotateY( Math.PI / 3 );
  } );
  let firstStep = true;
  React.useEffect( () => {
    geomRef.current?.rotateX( Math.PI );
    geomRef.current?.rotateX( -Math.PI / 4 );
    geomRef.current?.rotateY( Math.PI );
    if( firstStep ) {
      curveRef.current?.moveAlongCurve( 0.044 );
      firstStep = false;
      // eslint-disable-next-line
    }

    ref2.current?.rotateX( -Math.PI / 6 );

    // geomRef.current?.rotateX( Math.PI / 6 );

    // const linePos = textLineRef.current.geometry.attributes.position;
    // const arr = [];
    // for( let i = 0, j = linePos.array.length; i < j; i += 3 ) {
    //   arr.push( linePos.array.slice( i, i + 3 ) );
    // }

    // console.log( arr.map( pos => new THREE.Vector3( pos[ 0 ], pos[ 1 ], pos[ 2 ] ) ) );
    // console.log( textLineRef.current.matrixWorld.elements );

    // const handlePos = arr.map( pos => new THREE.Vector3( pos[ 0 ], pos[ 1 ], pos[ 2 ] ) );
    // [
    //   { x: -0.4, y: 0, z: 0.5 },
    //   { x: 0, y: 0.4, z: 0.5 },
    //   { x: 0.4, y: 0, z: 0.5 },
    // ]
    // .map( hand => new THREE.Vector3( ...Object.values( hand ) ) );

    // const curve = new THREE.CatmullRomCurve3( handlePos, false, 'centripetal' );
  }, [ ] );

  const edges = new THREE.EdgesGeometry( RingGLTF.nodes.LineForRightText.geometry );
  // edges.scale( 0.01, 0.01, 0.01 );
  const lineEdg = new THREE.LineSegments( edges );
  ( lineEdg.material as THREE.Material ).depthTest = false;
  ( lineEdg.material as THREE.Material ).opacity = 1;
  ( lineEdg.material as THREE.Material ).opacity = 1;
  ( lineEdg.material as THREE.Material ).transparent = true;
  ( lineEdg.material as any ).color = { r: 0, g: 0, b: 1 };
  // ( lineEdg.material as THREE.Material ).color = [ 1, 1, 1 ];
  // lineEdg.position.y = 2 * 0.01;
  // lineEdg.position.x = 2 * 0.01;
  // lineEdg.position.z = 2 * 0.01;

  return (
    <>
      <group
        ref={ group }
        { ...props }
        dispose={ null }
        scale={ 0.01 }
        // rotation={ [ Math.PI / 1.5, Math.PI / 0.9, Math.PI / 0.8 ] }
      >
        <group
          name='Scene'
          rotation={ [ 1.5 * Math.PI, 0, 0 ] }
        >
          <mesh
            name='Ring'
            castShadow
            receiveShadow
            geometry={ RingGLTF.nodes.Mesh051.geometry }
            material={ RingGLTF.materials[ 'Material.013' ] }
          />
          { /* <primitive name='lineForText' object={ lineEdg } ref={ textLineRef } /> */ }
          {
            shownRuby && (
              <mesh
                name='Ruby'
                castShadow
                receiveShadow
                geometry={ RingGLTF.nodes.Mesh050.geometry }
                material={ new THREE.MeshBasicMaterial( { color: new THREE.Color( RubyColor ?? 'hotpink' ), transparent: true } ) }
              />
            )
          }
          {
            shownLeftImage && (
              <>
                <mesh
                  name='LeftSideImage'
                  castShadow
                  receiveShadow
                  geometry={ RingGLTF.nodes.LeftSideImage.geometry }
                  material={ RingGLTF.materials[ 'Material.012' ] }
                />
                <mesh
                  name='LeftSideImageText'
                  castShadow
                  receiveShadow
                  geometry={ RingGLTF.nodes.LeftSideImageText.geometry }
                  material={ RingGLTF.materials[ 'Material.011' ] }
                />
              </>
            )
          }
          {
            shownRightImage && (
              <mesh
                name='RightSideImage'
                castShadow
                receiveShadow
                geometry={ RingGLTF.nodes.RightSideImage.geometry }
                material={ RingGLTF.materials[ 'Material.012' ] }
              />
            )
          }
          <mesh
            name='LineForRightText'
            castShadow
            receiveShadow
            geometry={ RingGLTF.nodes.LineForRightText.geometry }
            material={ RingGLTF.materials[ 'Material.001' ] }
          />
        </group>

      </group>

      <group
        rotation={ [ 0, -Math.PI / 2, 0 ] }
        position={ [ -0.09, 0.09, -0.05 ] }
        ref={ ref2 }
      >
        <mesh
          material={ RingGLTF.materials[ 'Material.013' ] }
        >
          <textGeometry
            args={ [
              LeftText || '2020',
              {
                font,
                size: 0.03,
                height: 0.005,
              },
            ] }
            ref={ geomRef2 }
          />
          { /* <meshNormalMaterial attach='material' /> */ }
        </mesh>
      </group>

      <CurveModifier
        ref={ curveRef }
        curve={ curve }
      >
        <group>
          <mesh
            material={ RingGLTF.materials[ 'Material.013' ] }
          >
            <textGeometry
              args={ [
                RightText || 'BBA',
                {
                  font,
                  size: 0.03,
                  height: 0.005,
                },
              ] }
              ref={ geomRef }
            />
            { /* <meshNormalMaterial attach='material' /> */ }
          </mesh>
        </group>
      </CurveModifier>
      <primitive object={ line } />
    </>
  );
};

Ring.defaultProps = defaultProps;

useGLTF.preload( '/defaultglb.glb' );
useGLTF.preload( '/rybin.glb' );
