import qs from 'query-string';
import { paramCase } from 'param-case';
import { ObjEntries } from '@arthurka/ts-utils';
import { defaultURLParams } from '../defaultData';

export interface URLParams {
  rubyColor: string;
  leftImage: string;
  rightImage: string;
  leftText: string;
  rightText: string;
}

function hydrateURLParams( e: Record<string, string> ): URLParams {
  return {
    rubyColor: e[ paramCase( 'rubyColor' ) ] as URLParams['rubyColor'] ?? defaultURLParams.rubyColor,
    leftText: e[ paramCase( 'leftText' ) ] as URLParams['leftText'] ?? defaultURLParams.leftText,
    rightText: e[ paramCase( 'rightText' ) ] as URLParams['rightText'] ?? defaultURLParams.rightText,
    leftImage: e[ paramCase( 'leftImage' ) ] as URLParams['leftImage'] ?? defaultURLParams.leftImage,
    rightImage: e[ paramCase( 'rightImage' ) ] as URLParams['rightImage'] ?? defaultURLParams.rightImage,
  };
}

function correctURL( params: URLParams ): void {
  const newSearchParams = qs.stringify(
    Object.fromEntries(
      ObjEntries( params )
        .filter( ( [ key, val ] ) => defaultURLParams[ key ] !== val )
        .map( ( [ key, val ] ) => [ paramCase( key ), val ] ),
    ),
    { sort: false },
  );
  const newHref = newSearchParams ? `/?${ newSearchParams }` : '/';

  window.history.replaceState( document.title, document.title, newHref );
}

function parseUrlParams(): URLParams {
  const rawParams = Object.fromEntries(
    [ ...new URLSearchParams( window.location.search ) ]
      .map( ( [ key, val ] ) => [ key.toLowerCase(), val ] ),
  );
  const params = hydrateURLParams( rawParams );

  correctURL( params );

  return params;
}

export const urlParams = parseUrlParams();
